import {Link} from 'react-router-dom';

import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import dateFormat from 'dateformat';

// For Dashboard Loader
import DashboardLoader from '../include/DashboardLoader';
// For Dashboard Loader
// For Noticebaord Loader
import NoticeboardLoader from '../include/NoticeboardLoader';
// For Noticebaord Loader

function Mypaymenthistorycontent(props) {

    const txtblue={
      color: '#1500b2',
      fontWeight: '700',
      fontSize: 'medium'
    };

    const txtwhite={
      color: '#000',
      fontWeight: '700',
      fontSize: 'medium'
    };

    let [studname,setStudname]=useState('');
    let [enrollid,setEnrollid]=useState('');
    let [studstatus,setStudstatus]=useState('');
    let [batchname,setBatchname]=useState('');
    let [pic,setPic]=useState(null);

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");


    //LOADER
    let [loader,setLoader]=useState(false);
    //LOADER

    // async function getToken() {

    //   setLoader(true);

    //   var tokenval=localStorage.getItem("tokenID");
    //   if (tokenval!=null) {
    //     var resp=await fetch("https://safalata.in/safalatalara/api/gettoken", {
    //       headers: {
    //         "Content-Type":"application/json",
    //         "Authorization":"Bearer "+tokenval
    //       }
    //     });
    //     var data=await resp.json();
    //     setStudname(data.name);
    //     setEnrollid(data.enroll_id);
    //     setStudstatus(data.status);
    //     setPic(data.pic);
    //     if (data.name.length>1 && localStorage.getItem("enrollid")==null) {
    //       localStorage.setItem("enrollid",data.enroll_id);
    //     }

    //     var fd=new FormData();
    //     fd.append("batchid",data.batch_slno);
    //     var resp2=await axios.post("https://safalata.in/safalatalara/api/getbatchname",fd);
    //     var data2=resp2.data;
    //     setBatchname(data2);

    //     setLoader(false);

    //   } else {
    //     window.location.href="/";
    //   }

    // }

    // const listcount=0;

    //PAYMENT LIST
    let i=0;
    let j=0;

    let [paidlistarr,setPaidlistarr]=useState([]);
    let [unpaidlistarr,setUnpaidlistarr]=useState([]);


    function getNostorage() {
      if (localStorage.getItem("livesetid")!=null) {
        localStorage.removeItem("livesetid");
      }
    }


    async function getpaymenthistory() {
        // alert(localStorage.getItem("enrollid"));
        var fd=new FormData();
        fd.append("enrollid",localStorage.getItem("enrollid"));
        var resp=await axios.post("https://safalata.in/safalatalara/api/mypaymenthistory",fd);
        var data=resp.data;
        setPaidlistarr(data.paidlist);
        setUnpaidlistarr(data.unpaidlist);
        // console.log()
    }

    let [noticename, setNoticename]=useState("");
    let [noticedatetime, setNoticedatetime]=useState("");

    async function getnoticeboard() {
        setLoader(true);
        var resp=await axios.get("https://safalata.in/safalatalara/api/getnoticeboard");
        var data=resp.data;
        setNoticename(data.noticename);
        setNoticedatetime(data.noticedatetime);
        setLoader(false);
    }

    async function getBatchname() {
        setLoader(true);
        var fd=new FormData();
        fd.append("batchid",localStorage.getItem("batchid"));
        var resp2=await axios.post("https://safalata.in/safalatalara/api/getbatchname",fd);
        var data2=resp2.data;
        setBatchname(data2);
        setPic(localStorage.getItem("pic"));
        setStudname(localStorage.getItem("name"));
        setEnrollid(localStorage.getItem("enrollid"));
        setStudstatus(localStorage.getItem("status"));
        setLoader(false);
    }

    useEffect(()=>{
      // getToken();
      getBatchname();
      getpaymenthistory();
      getNostorage();

      getnoticeboard();

    },[])

    return(
        <>        
        
        {/* {listcount} */}

        <div id="content">
            <div className="p-4 contentWrapper">
                <div className="row">
                    <div className="col-lg-12 dasboard-wrapper">
                    <h4 className="mb-3 font-weight-bold text-success">Dasboard</h4>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-sm-7">
                    {!loader?
                        <p className="dashboardtxt">
                          {/* {localStorage.getItem("pic")!=null?<img src={"https://safalata.in/safalatalara/uploadpic/"+pic} alt="" className="profile_img rounded border border-success" style={{float: 'left', width: '100px', marginRight: 10, borderRadius: '5px'}} />:''} */}
                          <span style={{fontSize: 'x-large', color: '#870017', textTransform: 'uppercase'}}>{studname}</span><br/>
                          Batch Name : <span style={txtblue}>{batchname}</span><br/>
                          Enrollment ID : <span style={txtblue}>{enrollid}</span><br/>
                          Student Status : {studstatus==1?<span className="text text-success">ACTIVE</span>:<span className="text text-danger">INACTIVE</span>}
                        </p>                  
                        // <p className="dashboardtxt">{studname}</p>
                        // <p className="dashboardtxt">Batch Name : {batchname}</p>
                        // <p className="dashboardtxt">Enrollment ID : {enrollid}</p>
                        // <p className="dashboardtxt">Student Status : {studstatus==1?'ACTIVE':'INACTIVE'}</p>
                    
                    :<DashboardLoader/>}
                    </div>
                    {/* <div className="col-sm-3">
                      
                    </div> */}
                    <div className="col-sm-5">
                    {!loader?
                        <h6 className="card-title text-defult font-weight-bold">
                          <span style={{fontWeight:700, fontSize: 'large'}}>Notice Board</span><br/><br/>
                          <span style={{fontWeight:600, fontSize: 'medium'}} className="text text-danger">Notice : {noticename}. ( Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")} )</span><br/>
                          {/* <span style={{fontWeight:700, fontSize: 'medium'}}>Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")}</span> */}
                        </h6>
                        // <p>Notice : # Next new batch (Batch-13) is commenced from 8th January 2022 at 12.30 p.m. # We commence new batch in two different sessions - January (1st week) and July (1st Week). # This is 12 months course.
                        //  Date : 20-08-2021</p>
                    :<NoticeboardLoader/>}
                    </div>
                </div>

               <div className="row mb-4">
                        <div className="col-sm-12">
                           <h6 className="card-title text-defult font-weight-bold" style={{fontSize: 'large'}}>MY PAYMENT HISTORY</h6>
                           <div className="bg_white border rounded">  
                           <table className="table">
                                <thead style={{background:'#910000',color:'#fff'}}>
                                  <tr>
                                    {/* <th className='text-center'>#SL</th> */}
                                    <th className='text-center'>Unpaid List</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                 <td>
                                 {unpaidlistarr.map((e,j)=>
                                 
                                    <span className='inline' style={{fontWeight:700,}}>{e.payment_month}</span>
                                  
                                 )}
                                 </td>
                                </tr>
                                </tbody>
                              </table>          
                              <table className="table">
                                <thead style={{background:'#005e05',color:'#fff'}}>
                                  <tr>
                                    <th className='text-center'>#SL</th>
                                    <th>Paid List with Remarks</th>
                                    {/* <th className='text-center'>Remarks</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                      
                                 {paidlistarr.map((e,i)=>
                                  <tr key={e.payment_list_id}>
                                    <td className='text-center' style={{fontWeight:700, paddingTop: 20}}>{i+1}</td>
                                    <td style={{fontWeight:700}}>{e.payment_list+' = '+dateFormat(e.payment_date, "dd/mm/yyyy")}<br/>[ {e.payment_remark} ]</td>
                                    {/* <td className='text-center' style={{fontWeight:700}}>{e.payment_remark}</td> */}
                                  </tr>
                                 )}

                                </tbody>
                              </table>
                        </div>
                    </div>
               </div>
           

            </div>  
        </div>
       
        </>
    );
}

export default Mypaymenthistorycontent;