import react from "react";
import Header from "./../include/Header";
import Sidebar from "./../include/Sidebar";
import Updateprofilepiccontent from "./../content/Updateprofilepiccontent";

function Updateprofilepic() {
    return(
        <>
        <Header />
        <div className="wrapper"> 
            <Sidebar />
            <Updateprofilepiccontent />        
        </div>
        </>
    );
}

export default Updateprofilepic;