import react from "react";
import { useEffect, useState } from "react";

function Demo() {

    // let [tokenname,setTokenname]=useState("");

    // async function getToken() {
    //     var tokenval=localStorage.getItem("studtoken");

    //     if (tokenval!=null) {
    //         var response=await fetch("http://localhost/ADMINSAFALATA/safalata/api/gettoken", {
    //             headers: {
    //                 "Content-Type":"application/json",
    //                 "Authorization":"Bearer "+tokenval
    //             }
    //         });
    //         var data=await response.json();

    //         console.log(data.name)
    //     } else {
    //         window.location.href="/";
    //     }
    // }

    const name="teaching&apos; yoga";


    useEffect(()=>{
        // getToken();
    },[])

    return(
        <>
        Name : {name.replace(/&apos;/g, "'")}
        </>
    );
}

export default Demo;