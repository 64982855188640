import React from "react"
import ContentLoader from "react-content-loader"

const DashboardLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={600}
    height={115}
    viewBox="0 0 600 115"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="7" y="7" rx="5" ry="5" width="278" height="18" /> 
    <rect x="8" y="33" rx="5" ry="5" width="112" height="20" /> 
    <rect x="142" y="32" rx="0" ry="0" width="16" height="10" /> 
    <rect x="180" y="31" rx="5" ry="5" width="138" height="23" /> 
    <rect x="142" y="45" rx="0" ry="0" width="16" height="10" /> 
    <rect x="10" y="62" rx="5" ry="5" width="130" height="20" /> 
    <rect x="161" y="60" rx="0" ry="0" width="16" height="10" /> 
    <rect x="161" y="73" rx="0" ry="0" width="16" height="10" /> 
    <rect x="190" y="61" rx="5" ry="5" width="95" height="23" /> 
    <rect x="10" y="91" rx="5" ry="5" width="130" height="20" /> 
    <rect x="153" y="92" rx="0" ry="0" width="16" height="10" /> 
    <rect x="153" y="105" rx="0" ry="0" width="16" height="10" /> 
    <rect x="187" y="90" rx="5" ry="5" width="149" height="23" />
  </ContentLoader>
)

export default DashboardLoader