import react, { useEffect, useState } from "react";
// import {Link} from 'react-router-dom';

import {useNavigate} from "react-router-dom";

function Maincontent() {

    let [btnflag,setBtnflag]=useState(true);

    let navigate = useNavigate();

    // const baseurl="https://www.safalata.in/safalatalara";

    useEffect(()=>{
      if (localStorage.getItem("tokenID")!=null) {
          window.location.href="/dashboard";
      }
    },[])

    
  return(
    <>
    <div className="loginBoxWrap">
        {/* <div className="loginBox" style={{paddingTop: '520px'}}> */}
        <div className="loginBox" style={{paddingTop: '350px'}}>
          <div className="logoRow"><img src="images/logo2.png" alt="" style={{width: '40%'}}/></div>
        	<div className="bg_white2" style={{background: '#fff'}}>
          <h2 style={{fontSize: 'medium'}}>Shraddha Group of Education</h2>
          <div className="loginBoxContener p-4" style={{background: '#fff'}}>
          <h5 className="text-center" style={{color: '#000', fontSize: 'large', fontWeight: '700'}}>PREPARATION FOR</h5>
            
            <ul class="list-group">
                <li class="list-group-item">Staff Selection Commission (Junior Hindi Translator/ Senior Hindi Translator/ Junior Translation Officer/Senior Translation Officer)</li>
                <li class="list-group-item">Institute of Banking Personnel Selection (IBPS), Specialist Officer(SO)- Rajbhasha Adhikari</li>
                <li class="list-group-item">Railway Recruitment Board(RRB) - Hindi Translator/ JTO</li>
                <li class="list-group-item">Food Corporation of India - Assistant Grade - II (Hindi)</li>
                <li class="list-group-item">Rajbhasha Adhikari/ Official Language Officer/ Senior Translation Officer/ Junior Translation Officer/Senior Hindi Translator (SHT)/ Junior Hindi Translator (JHT), Hindi Assistant posts in Loksabha, Rajsabha, RBI, NABARD, all PSUs, Central Govt Departments, National Educational Institutes, Autonomous Bodies</li>
            </ul>

            <h5 className="text-center" style={{color: '#000', fontSize: 'large', fontWeight: '700', marginTop: '20px', marginBottom: '20px'}}>ELIGIBILITY CRITERIA</h5>
            
            <ul class="list-group">
                <li class="list-group-item">Master's degree in Hindi with English as an elective subject or as the medium of examination at the degree level. OR</li>
                <li class="list-group-item">Master's degree in English with Hindi as an elective subject or as the medium of examination at the degree level.</li>
                <li class="list-group-item">Course Duration - 1 Year</li>
                <li class="list-group-item">Courses Commence in the month of January and July Every Year</li>
            </ul>

              <div className="form-group row text-center" style={{paddingTop: '20px'}}>
                <div className="col-sm-12">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="inlineCheckbox2" onChange={(ev)=>{
                      if (ev.target.checked) {
                        setBtnflag(false);
                      } else {
                        setBtnflag(true)
                      }
                    }} />
                        <label class="form-check-label" for="inlineCheckbox2"> I have read all the above instructions</label>
                    </div>
                </div>
              </div>

              <div className="form-group row text-center" style={{paddingBottom: '20px'}}>
                <div className="col-sm-12">
                  <div className="form-check p-0">
                    <button className="btn btn-primary" onClick={()=>{
                        let path = `/`; 
                        navigate(path);
                    }} disabled={btnflag}>Go To My Safalata Dashboard</button>
                  </div>
                </div>
              </div>

          </div>
          </div>
          <div className="small mt-2 copyrighttext">SAFALATA - Shraddha Group of Education <br/>&copy; Copyright 2022. All Rights Reserved.<br/>Powered By CHEAPWEBGURU.COM<br></br></div>
      </div>   
    </div>
    
    </>

  );
}

export default Maincontent;







