import react from "react";
import Header from "./../include/Header";
import Sidebar from "./../include/Sidebar";
import Mypaymenthistorycontent from "./../content/Mypaymenthistorycontent";

function Mypaymenthistory() {
    return(
        <>
        <Header />
        <div className="wrapper"> 
            <Sidebar />
            <Mypaymenthistorycontent />        
        </div>
        </>
    );
}

export default Mypaymenthistory;