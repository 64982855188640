import React, {useEffect} from 'react';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Myprofile from './pages/Myprofile';
import Changepassword from './pages/Changepassword';
import Updateprofilepic from './pages/Updateprofilepic';
import Mypaymenthistory from './pages/Mypaymenthistory';
import Startliveexam from './pages/Startliveexam';
import Liveexam from './pages/Liveexam';
import Liveexamresult from './pages/Liveexamresult';
import LiveScoreView from './pages/LiveScoreView';
import Liveexamreview from './pages/Liveexamreview';
import Monthwisescore from './pages/Monthwisescore';

import Startpracticeexam from './pages/Startpracticeexam';
import Practiceexam from './pages/Practiceexam';
import Practiceexamresult from './pages/Practiceexamresult';

import ForgotPassword from './pages/ForgotPassword';
import Updatepasswordcontent from './content/Updatepasswordcontent';

import Main from './pages/Main';

import Demo from './pages/Demo';

import "./App.css";


import {BrowserRouter,Routes,Route} from 'react-router-dom';
import Practiceexamqa from './pages/Practiceexamqa';
import Liveexamqa from './pages/Liveexamqa';



function App() {

  function openFullscreen() {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
      document.documentElement.msRequestFullscreen();
    }
  }

  useEffect(()=>{
    // getchecklogin();
    openFullscreen();
  }, []);
  
  return (
    <> 
     {/* <BrowserRouter basename="/stud"> */}
     <BrowserRouter>
     {/* <Router basename="/stud"> */}
        
        <Routes>
          <Route exact path='/' element={<Login />} />
          <Route exact path='/dashboard' element={<Dashboard />} />
          <Route exact path='/my-profile' element={<Myprofile />} />
          <Route exact path='/change-password' element={<Changepassword />} />
          <Route exact path='/update-profile-pic' element={<Updateprofilepic />} />
          <Route exact path='/my-payment-history' element={<Mypaymenthistory />} />

          <Route exact path='/startliveexam' element={<Startliveexam />} />
          <Route exact path='/liveexam/:qsl' element={<Liveexam />} />
          <Route exact path='/liveexamqa/:qsl' element={<Liveexamqa />} />
          <Route exact path='/liveexamresult' element={<Liveexamresult />} />
          <Route exact path="/livescoreview/:suid/:livexmid" element={<LiveScoreView/>}/>
          <Route exact path='/liveexamreview/:examdate/:examid' element={<Liveexamreview />} />
          <Route exact path='/monthwisescore' element={<Monthwisescore />} />

          <Route exact path='/startpracticeexam' element={<Startpracticeexam />} />
          <Route exact path='/practiceexam/:qsl' element={<Practiceexam />} />
          <Route exact path='/practiceexamresult' element={<Practiceexamresult />} />

          <Route exact path='/practiceexamqa/:qsl' element={<Practiceexamqa />} />

          <Route exact path='/forgot-password' element={<ForgotPassword />} />
          <Route exact path='/update-password' element={<Updatepasswordcontent />} />

          <Route exact path='/main' element={<Main />} />

          <Route exact path='/demo' element={<Demo />} />

        </Routes>
        {/* </Router> */}
      </BrowserRouter>

   

    </>
  );
}

export default App;
