import react from "react";
import Header from "./../include/Header";
import Sidebar from "./../include/Sidebar";
import Liveexamresultcontent from "./../content/Liveexamresultcontent";

function Liveexamresult() {
    return(
        <>
        <Header />
        <div className="wrapper"> 
            <Sidebar />
            <Liveexamresultcontent />        
        </div>
        </>
    );
}

export default Liveexamresult;