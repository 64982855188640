import {Link} from 'react-router-dom';

import react, { useRef, useEffect, useState } from "react";

import { useParams } from 'react-router-dom';

import axios from 'axios';

import dateFormat from 'dateformat';

// For Dashboard Loader
import DashboardLoader from '../include/DashboardLoader';
// For Dashboard Loader
// For Noticebaord Loader
import NoticeboardLoader from '../include/NoticeboardLoader';
// For Noticebaord Loader

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader



function Liveexamreviewcontent(props) {

    const txtblue={
      color: '#1500b2',
      fontWeight: '700',
      fontSize: 'medium'
    };

    const txtwhite={
      color: '#000',
      fontWeight: '700',
      fontSize: 'medium'
    };

    const params=useParams();

    let [isbtn,setIsbtn]=useState(true);
    //localStorage.setItem("studid",1000);

    // if (localStorage.getItem("studtoken")==null) {
    //   window.location.href="/";
    // }

    let [studname,setStudname]=useState('');
    let [enrollid,setEnrollid]=useState('');
    let [studstatus,setStudstatus]=useState('');
    let [batchname,setBatchname]=useState('');


    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");


    //LOADER
    let [loader,setLoader]=useState(false);
    let [tableloader,setTableloader]=useState(false);
    //LOADER

    let [pic,setPic]=useState(null);

    async function getToken() {

      setLoader(true);

      var tokenval=localStorage.getItem("tokenID");
      if (tokenval!=null) {
        var resp=await fetch("https://safalata.in/safalatalara/api/gettoken", {
          headers: {
            "Content-Type":"application/json",
            "Authorization":"Bearer "+tokenval
          }
        });
        var data=await resp.json();
        setStudname(data.name);
        setEnrollid(data.enroll_id);
        setStudstatus(data.status);
        setPic(data.pic);
        
        if (data.name.length>1 && localStorage.getItem("enrollid")==null) {
          localStorage.setItem("enrollid",data.enroll_id);
        }

        var fd=new FormData();
        fd.append("batchid",data.batch_slno);
        var resp2=await axios.post("https://safalata.in/safalatalara/api/getbatchname",fd);
        var data2=resp2.data;
        setBatchname(data2);

        setLoader(false);

      } else {
        window.location.href="/";
      }

    }

    // const reviewcount=0;

    //LIVE EXAM REVIEW
    let [examreviewarr,setExamreviewarr]=useState([]);
    let [examname,setExamname]=useState("");

    let [correctarr,setCorrectarr]=useState([]);
    // let [qidcount,setQidcount]=useState(0);

    async function liveexamreview() {
        // alert(localStorage.getItem("enrollid"));
        // alert(params.examdate);
        // alert(params.examid);
      setTableloader(true);

      var fd=new FormData();
      fd.append("enrollid",localStorage.getItem("enrollid"));
      fd.append("examdate",params.examdate);
      fd.append("examid",params.examid);
      
      var resp=await axios.post("https://safalata.in/safalatalara/api/liveexamreview",fd);
      var data=resp.data;
        // var resp=await fetch("https://safalata.in/safalatalara/api/liveexamreview", {
        //     body: fd,
        //     method: "post"
        // });
        // var data=await resp.json();
      setExamreviewarr(data);

    //   console.log(data);
      
      var fd2=new FormData();
      fd2.append("examid",params.examid);
      var resp2=await axios.post("https://safalata.in/safalatalara/api/liveexamreviewdetails",fd2);
      var data2=resp2.data;
      setExamname(data2);

      setTableloader(false);

    //   setCorrectarr(data.correct);
    //   setQidcount(data.reviewcount);
    }


    function getNostorage() {
      if (localStorage.getItem("livesetid")!=null) {
        localStorage.removeItem("livesetid");
      }
    }

    let [noticename, setNoticename]=useState("");
    let [noticedatetime, setNoticedatetime]=useState("");

    async function getnoticeboard() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getnoticeboard");
        var data=resp.data;
        setNoticename(data.noticename);
        setNoticedatetime(data.noticedatetime);
    }

    useEffect(()=>{
      getToken();
      liveexamreview();
      getNostorage();

      getnoticeboard();

    },[])

    return(
        <>        
        
        

        <div id="content">
            <div className="p-4 contentWrapper">
                <div className="row">
                    <div className="col-lg-12 dasboard-wrapper">
                    <h4 className="mb-3 font-weight-bold text-success">Live Exam Review : </h4>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-sm-7">
                    {!loader?
                        <p className="dashboardtxt">
                          {/* {pic!=null?<img src={"https://safalata.in/safalatalara/uploadpic/"+pic} alt="" className="profile_img rounded border border-success" style={{float: 'left', width: '100px', marginRight: 10, borderRadius: '5px'}} />:''} */}
                          <span style={{fontSize: 'x-large', color: '#870017', textTransform: 'uppercase'}}>{studname}</span><br/>
                          Batch Name : <span style={txtblue}>{batchname}</span><br/>
                          Enrollment ID : <span style={txtblue}>{localStorage.getItem("enrollid")}</span><br/>
                          Student Status : {studstatus==1?<span className="text text-success">ACTIVE</span>:<span className="text text-danger">INACTIVE</span>}
                        </p>                        
                        // <p className="dashboardtxt">{studname}</p>
                        // <p className="dashboardtxt">Batch Name : {batchname}</p>
                        // <p className="dashboardtxt">Enrollment ID : {enrollid}</p>
                        // <p className="dashboardtxt">Student Status : {studstatus==1?'ACTIVE':'INACTIVE'}</p>
                    
                    :<DashboardLoader/>}
                    </div>
                    {/* <div className="col-sm-3">
                      
                    </div> */}
                    <div className="col-sm-5">
                    {!loader?
                        <h6 className="card-title text-defult font-weight-bold">
                          <span style={{fontWeight:700, fontSize: 'large'}}>Notice Board</span><br/><br/>
                          <span style={{fontWeight:600, fontSize: 'medium'}} className="text text-danger">Notice : {noticename}. ( Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")} )</span><br/>
                          {/* <span style={{fontWeight:700, fontSize: 'medium'}}>Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")}</span> */}
                        </h6>
                        // <p>Notice : # Next new batch (Batch-13) is commenced from 8th January 2022 at 12.30 p.m. # We commence new batch in two different sessions - January (1st week) and July (1st Week). # This is 12 months course.
                        //  Date : 20-08-2021</p>
                    :<NoticeboardLoader/>}
                    </div>
                </div>

                <div className="row mb-4">
                     <div className="col-sm-12">
                         <h6 className="card-title text-defult font-weight-bold"><Link to="/liveexamresult" style={{fontSize: 'x-large;'}}> {"<< BACK"}</Link> | LIVE EXAM REVIEW : [ EXAM - {examname} | EXAM DATE - {params.examdate} ]</h6>
                         <div className="bg_white border rounded">      
                         {!tableloader?      
                            <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th>QID</th>
                                    <th>Question</th>
                                    <th>Answers</th>
                                    <th>Correct</th>
                                    <th>Answered</th>
                                    <th className='text-center'>Mark Obtained</th>
                                  </tr>
                                </thead>
                                <tbody>
                      

                                {examreviewarr.map((e)=>

                                  <tr key={e.studliveexamsheet_slno}>
                                    
                                    <td>{e.qid}</td>
                                    <td>{e.question}</td>  
                                    
                                    {/* {e.qidcount} */}
                                    
                                    <td>
                                    {e.child.map((a)=>
                                    <span key={a.answer_slno}>&#8226; {a.answer}<br/></span>
                                    )}
                                    </td>
                                    
                                    {/* {correctarr.map((cr)=>
                                    <td key={cr.correctid}>{cr.correctans}</td>
                                    )} */}
                                    {/* <td></td> */}
                                    {/* <td></td> */}
                                    <td> 
                                    {e.correct.map((c)=>
                                    <span key={c.answer_slno}>{c.answer}</span>
                                    )}
                                    </td>
                                    <td>
                                    {e.answered.map((b)=>
                                    e.stud_ans_mark==1.00?
                                    <span key={b.answer_slno} style={{color: '#0e6b00',fontWeight: 700}}>{b.answer}</span>
                                    :e.stud_ans_mark=='-0.25'?
                                    <span key={b.answer_slno} style={{color: '#9c0003',fontWeight: 700}}>{b.answer}</span>
                                    :
                                    <span key={b.answer_slno} style={{color: '#383838',fontWeight: 700}}>{b.answer}</span>
                                    )}
                                    </td>

                                    <td className='text-center'>
                                    {e.stud_ans_mark==1.00?
                                    <span style={{color: '#0e6b00',fontWeight: 700}}>{e.stud_ans_mark}</span>
                                    :e.stud_ans_mark=='-0.25'?
                                    <span style={{color: '#9c0003',fontWeight: 700}}>{e.stud_ans_mark}</span>
                                    :
                                    <span style={{color: '#383838',fontWeight: 700}}>{e.stud_ans_mark}</span>
                                    }
                                    </td>
                                  
                                  </tr>
                                  
                                  )}


                                </tbody>
                              </table>
                        :<TableLoader/>}
                        </div>
                    </div>
                </div>
           

            </div>  
        </div>
       
        </>
    );
}

export default Liveexamreviewcontent;