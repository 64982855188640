import React from "react"
import ContentLoader from "react-content-loader"

const NoticeboardLoader = (props) => (
    <ContentLoader 
    speed={2}
    width={240}
    height={140}
    viewBox="0 0 240 140"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="25" y="8" rx="5" ry="5" width="123" height="16" /> 
    <rect x="25" y="38" rx="5" ry="5" width="330" height="16" /> 
    <rect x="26" y="65" rx="5" ry="5" width="330" height="16" /> 
    <rect x="26" y="93" rx="5" ry="5" width="330" height="16" /> 
  </ContentLoader>
)

export default NoticeboardLoader