import react from "react";
import Header from "./../include/Header";
import Sidebar from "./../include/Sidebar";
import Practiceexamresultcontent from "./../content/Practiceexamresultcontent";

function Practiceexamresult() {
    return(
        <>
        <Header />
        <div className="wrapper"> 
            <Sidebar />
            <Practiceexamresultcontent />        
        </div>
        </>
    );
}

export default Practiceexamresult;