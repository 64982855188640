import react from "react";
import ForgotPasswordcontent from "../content/ForgotPasswordcontent";

function ForgotPassword() {
  return(
    <>
    <ForgotPasswordcontent />
    </>

  );
}

export default ForgotPassword;

