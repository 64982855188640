import react from "react";
import Maincontent from "../content/Maincontent";

function Main() {
  return(
    <>
    <Maincontent />     
    </>

  );
}

export default Main;







