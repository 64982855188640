import react from "react";
import Examheader from "../include/Examheader";
import Liveexamcontent from "./../content/Liveexamcontent";

function Liveexam() {
    return(
        <>
        <Examheader />
        <div className="wrapper"> 
            {/* <Sidebar /> */}
            <Liveexamcontent />        
        </div>
        </>
    );
}

export default Liveexam;