import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import {Link} from 'react-router-dom';

import dateFormat from 'dateformat';

import DateCountDown from 'react-date-countdown-timer';

// For Dashboard Loader
import DashboardLoader from '../include/DashboardLoader';
// For Dashboard Loader
// For Noticebaord Loader
import NoticeboardLoader from '../include/NoticeboardLoader';
// For Noticebaord Loader

import {useNavigate} from "react-router-dom";

function Startliveexamcontent(props) {

    let navigate = useNavigate();

    const txtblue={
      color: '#1500b2',
      fontWeight: '700',
      fontSize: 'medium'
    };

    const txtwhite={
      color: '#000',
      fontWeight: '700',
      fontSize: 'medium'
    };

    let [isbtn,setIsbtn]=useState(true);

    let [studname,setStudname]=useState('');
    let [enrollid,setEnrollid]=useState('');
    let [studstatus,setStudstatus]=useState('');
    let [batchname,setBatchname]=useState('');

    let [pic,setPic]=useState(null);

    if (localStorage.getItem("livesetid")==null) {
        window.location.href="/dashboard";
    }

    async function getBatchname() {
      console.log(localStorage.getItem("pic"));
      setLoader(true);
      var fd=new FormData();
      fd.append("batchid",localStorage.getItem("batchid"));
      var resp2=await axios.post("https://safalata.in/safalatalara/api/getbatchname",fd);
      var data2=resp2.data;
      setBatchname(data2);
      setPic(localStorage.getItem("pic"));
      setStudname(localStorage.getItem("name"));
      setEnrollid(localStorage.getItem("enrollid"));
      setStudstatus(localStorage.getItem("status"));
      setLoader(false);
  }

    //LOADER
    let [loader,setLoader]=useState(false);
    //LOADER
    
    //LIVE EXAM DETAILS
    let [examname,setExamname]=useState("");
    let [examtotalqst,setExamtotalqst]=useState("");
    let [examstdate,setExamstdate]=useState("");
    let [exameddate,setExameddate]=useState("");

    async function liveexamdetails() {
      let livesetid=localStorage.getItem("livesetid");
      var fd=new FormData();
      fd.append("livesetid",livesetid);
      var resp=await axios.post("https://safalata.in/safalatalara/api/liveexamdetails",fd);
      var data=resp.data;
      setExamname(data.livesetname);
      setExamstdate(data.stdate);
      setExameddate(data.eddate);
      setExamtotalqst(data.qcount);

      localStorage.setItem("examname",data.livesetname);
      localStorage.setItem("exameddate",data.eddate);
      localStorage.setItem("qcount",data.qcount);

      localStorage.setItem("shuffle",data.shuffle);
    }

    let [noticename, setNoticename]=useState("");
    let [noticedatetime, setNoticedatetime]=useState("");

    async function getnoticeboard() {
        setLoader(true);
        var resp=await axios.get("https://safalata.in/safalatalara/api/getnoticeboard");
        var data=resp.data;
        setNoticename(data.noticename);
        setNoticedatetime(data.noticedatetime);
        setLoader(false);
    }

    useEffect(()=>{
      // getToken();
      getBatchname();
      liveexamdetails();

      getnoticeboard();

    },[])

    return(
        <>        
        
        <div id="content">
            <div className="p-4 contentWrapper">
                <div className="row">
                    <div className="col-lg-12 dasboard-wrapper">
                    <h4 className="mb-3 font-weight-bold text-success">Dasboard</h4>
                    </div>
                </div>
                <div className="row mb-4">
                <div className="col-sm-7">
                    {!loader?
                        <p className="dashboardtxt">
                          {/* {localStorage.getItem("pic").length>1?
                          <img src={"https://safalata.in/safalatalara/uploadpic/"+localStorage.getItem("pic")} alt="" className="profile_img rounded border border-success" style={{float: 'left', width: '100px', marginRight: 10, borderRadius: '5px'}} />:''} */}
                          <span style={{fontSize: 'x-large', color: '#870017', textTransform: 'uppercase'}}>{studname}</span><br/>
                          Batch Name : <span style={txtblue}>{batchname}</span><br/>
                          Enrollment ID : <span style={txtblue}>{enrollid}</span><br/>
                          Student Status : {studstatus==1?<span className="text text-success">ACTIVE</span>:<span className="text text-danger">INACTIVE</span>}
                        </p>                        
                        // <p className="dashboardtxt">{studname}</p>
                        // <p className="dashboardtxt">Batch Name : {batchname}</p>
                        // <p className="dashboardtxt">Enrollment ID : {enrollid}</p>
                        // <p className="dashboardtxt">Student Status : {studstatus==1?'ACTIVE':'INACTIVE'}</p>
                    
                    :<DashboardLoader/>}
                    </div>
                    {/* <div className="col-sm-3">
                      
                    </div> */}
                    <div className="col-sm-5">
                    {!loader?
                        <h6 className="card-title text-defult font-weight-bold">
                          <span style={{fontWeight:700, fontSize: 'large'}}>Notice Board</span><br/><br/>
                          <span style={{fontWeight:600, fontSize: 'medium'}} className="text text-danger">Notice : {noticename}. ( Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")} )</span><br/>
                          {/* <span style={{fontWeight:700, fontSize: 'medium'}}>Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")}</span> */}
                        </h6>
                        // <p>Notice : # Next new batch (Batch-13) is commenced from 8th January 2022 at 12.30 p.m. # We commence new batch in two different sessions - January (1st week) and July (1st Week). # This is 12 months course.
                        //  Date : 20-08-2021</p>
                    :<NoticeboardLoader/>}
                    </div>
                </div>

                <div className="row mb-4">
                     <div className="col-sm-12">
                        
                        <div className="bg_white border rounded" style={{padding: '30px'}}>   

                        <h6 className="card-title text-defult font-weight-bold text-center" style={{fontSize: 'x-large'}}>GO TO TEST YOUR LIVE ONLINE EXAM</h6>    
                        
                            
                        <h2 className="startexamh2txt">This is Multiple Choice Live Set To Test Your Knowledge</h2>

                        <h2 className="startexamh2txt">Live Exam Set Name : <span className="startspantxt">{examname}</span></h2>

                        <h2 className="startexamh2txt">Number of Questions : {examtotalqst}</h2>

                        <h2 className="startexamh2txt">Exam Date &amp; Time : {dateFormat(examstdate, "dd-mm-yyyy ( HH:MM TT")} - {dateFormat(exameddate, "HH:MM TT )")}</h2>

                        <h2 className="startexamh2txt">Question Type : Multiple Choice</h2>

                        {/* <div className="text-center"><Link to="/liveexam/1" className="btn btn-success startexambtn">Start Exam</Link></div> */}

                        <div className="text-center">
                          <button className="btn btn-success startexambtn" onClick={async ()=>{
                            var fd=new FormData();
                            fd.append("livesetid",localStorage.getItem("livesetid"));
                            fd.append("enrollid",localStorage.getItem("enrollid"));
                            var resp=await axios.post("https://safalata.in/safalatalara/api/addstartliveexamschedule",fd);
                            var data=resp.data;
                            localStorage.setItem("exameddate",data.eddate);
                            let path = `/liveexamqa/1`;
                            navigate(path);
                          }}>Start Exam</button>
                        </div>

                        </div>
                    </div>
                </div>
           

            </div>  
        </div>
       
        </>
    );
}

export default Startliveexamcontent;