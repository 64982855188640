import react from "react";
import Logincontent from "../content/Logincontent";

function Login() {
  return(
    <>
    <Logincontent />     
    </>

  );
}

export default Login;







