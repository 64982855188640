import react from "react";
import Header from "./../include/Header";
import Sidebar from "./../include/Sidebar";
import Monthwisescorecontent from "./../content/Monthwisescorecontent";

function Monthwisescore() {
    return(
        <>
        <Header />
        <div className="wrapper"> 
            <Sidebar />
            <Monthwisescorecontent />        
        </div>
        </>
    );
}

export default Monthwisescore;