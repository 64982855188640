import React from "react"
import ContentLoader from "react-content-loader"

const QuestionLoader = (props) => (
    <ContentLoader 
    speed={2}
    width={600}
    height={125}
    viewBox="0 0 600 125"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="4" y="3" rx="5" ry="5" width="449" height="26" /> 
    <rect x="34" y="43" rx="5" ry="5" width="112" height="27" /> 
    <rect x="15" y="96" rx="5" ry="5" width="118" height="22" /> 
    <rect x="211" y="93" rx="5" ry="5" width="371" height="25" /> 
    <rect x="156" y="100" rx="0" ry="0" width="29" height="16" /> 
    <rect x="165" y="51" rx="0" ry="0" width="29" height="17" /> 
    <rect x="224" y="43" rx="5" ry="5" width="112" height="28" />
  </ContentLoader>
)

export default QuestionLoader