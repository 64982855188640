import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import dateFormat from 'dateformat';

// import DateCountDown from 'react-date-countdown-timer';

// For Dashboard Loader
import DashboardLoader from '../include/DashboardLoader';
// For Dashboard Loader

// For Noticebaord Loader
import NoticeboardLoader from '../include/NoticeboardLoader';
// For Noticebaord Loader

function Dashboardcontent(props) {

    const txtgreen={
      color: '#01691d',
      fontWeight: '700',
      fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtblue={
      color: '#1500b2',
      fontWeight: '700',
      fontSize: 'medium'
    };

    const txtwhite={
      color: '#000',
      fontWeight: '700',
      fontSize: 'small'
    };

    const txtwhite2={
      color: '#000',
      fontWeight: '700',
      fontSize: 'smaller'
    };

    // const baseurl="https://www.safalata.in/safalatalara";

    let [isbtn,setIsbtn]=useState(true);
    //localStorage.setItem("studid",1000);

    // if (localStorage.getItem("studtoken")==null) {
    //   window.location.href="/";
    // }

    let [studname,setStudname]=useState('');
    let [enrollid,setEnrollid]=useState('');
    let [studstatus,setStudstatus]=useState('');
    let [batchname,setBatchname]=useState('');
    let [pic,setPic]=useState(null);

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");


    //LOADER
    let [loader,setLoader]=useState(false);
    //LOADER


    // async function getToken() {

    //   setLoader(true);

    //   var tokenval=localStorage.getItem("tokenID");
    //   if (tokenval!=null) {
        
    //     var resp=await fetch("https://safalata.in/safalatalara/api/gettoken", {
    //       headers: {
    //         "Content-Type":"application/json",
    //         "Authorization":"Bearer "+tokenval
    //       }
    //     });
    //     var data=await resp.json();
    //     setStudname(data.name);
    //     setEnrollid(data.enroll_id);
    //     setStudstatus(data.status);
    //     setPic(data.pic);

    //     var fd=new FormData();
    //     fd.append("batchid",data.batch_slno);
    //     var resp2=await axios.post("https://safalata.in/safalatalara/api/getbatchname",fd);
    //     var data2=resp2.data;
    //     setBatchname(data2);

    //     setLoader(false);

    //   } else {
    //     window.location.href="/login";
    //   }

    // }

    if (localStorage.getItem("tokenID")==null) {
        window.location.href="/";
    }

    async function getBatchname() {
        console.log(localStorage.getItem("pic"));
        setLoader(true);
        var fd=new FormData();
        fd.append("batchid",localStorage.getItem("batchid"));
        var resp2=await axios.post("https://safalata.in/safalatalara/api/getbatchname",fd);
        var data2=resp2.data;
        setBatchname(data2);
        setPic(localStorage.getItem("pic"));
        setStudname(localStorage.getItem("name"));
        setEnrollid(localStorage.getItem("enrollid"));
        setStudstatus(localStorage.getItem("status"));
        setLoader(false);
    }

    //UPCOMING LIVE EXAM LIST
    let [upcominglivearr,setUpcominglivearr]=useState([]);

    async function upcomingliveexam() {
      // console.log(enrollid);
      // console.log(curdate);
      // let enrollid=localStorage.getItem("enrollid");
      var fd=new FormData();
      fd.append("enrollid",localStorage.getItem("enrollid"));
      fd.append("curdate",curdate);
      var resp=await axios.post("https://safalata.in/safalatalara/api/upcomingliveexam",fd);
      var data=resp.data;
      setUpcominglivearr(data);
    }


    //UPCOMING PRACTICE EXAM LIST
    let [upcomingpracticearr,setUpcomingpracticearr]=useState([]);

    async function upcomingpracticeexam() {
      var fd=new FormData();
      fd.append("enrollid",localStorage.getItem("enrollid"));
      fd.append("curdate",curdate);
      var resp=await axios.post("https://safalata.in/safalatalara/api/upcomingpracticeexam",fd);
      var data=resp.data;
      setUpcomingpracticearr(data);
    }

    // console.log(localStorage.getItem("enrollid"));
    // console.log(curdate);


    function getNostorage() {
      if (localStorage.getItem("livesetid")!=null) {
        localStorage.removeItem("livesetid");
      }
    }

    let [noticename, setNoticename]=useState("");
    let [noticedatetime, setNoticedatetime]=useState("");

    async function getnoticeboard() {
        setLoader(true);
        var resp=await axios.get("https://safalata.in/safalatalara/api/getnoticeboard");
        var data=resp.data;
        setNoticename(data.noticename);
        setNoticedatetime(data.noticedatetime);
        setLoader(false);
    }

    let [orderstatus, setOrderstatus]=useState("");
    async function getstudlogincheck() {
        var fd=new FormData();
        fd.append("enrollid",localStorage.getItem("enrollid"));
        var resp=await axios.post("https://safalata.in/safalatalara/api/studlogincheck",fd);
        var data=resp.data;
        setOrderstatus(data);
        // console.log(data);
    }

    useEffect(()=>{
      // getToken();
      
      getBatchname();
      upcomingliveexam();
      upcomingpracticeexam();
      getNostorage();

      getnoticeboard();

      getstudlogincheck();
      
    },[])

    return(
        <>        
        
        <div id="content">
            <div className="p-4 contentWrapper">
                <div className="row">
                    <div className="col-lg-12 dasboard-wrapper">
                    <h4 className="mb-3 font-weight-bold text-success">Dasboard</h4>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-sm-7">
                    {!loader?
                        <p className="dashboardtxt">
                          {/* {localStorage.getItem("pic").length>1?
                          <img src={"https://safalata.in/safalatalara/uploadpic/"+localStorage.getItem("pic")} alt="" className="profile_img rounded border border-success" style={{float: 'left', width: '100px', marginRight: 10, borderRadius: '5px'}} />:''} */}
                          <span style={{fontSize: 'x-large', color: '#870017', textTransform: 'uppercase'}}>{studname}</span><br/>
                          Batch Name : <span style={txtblue}>{batchname}</span><br/>
                          Enrollment ID : <span style={txtblue}>{enrollid}</span><br/>
                          Student Status : {studstatus==1?<span className="text text-success">ACTIVE</span>:<span className="text text-danger">INACTIVE</span>}
                        </p>                        
                        // <p className="dashboardtxt">{studname}</p>
                        // <p className="dashboardtxt">Batch Name : {batchname}</p>
                        // <p className="dashboardtxt">Enrollment ID : {enrollid}</p>
                        // <p className="dashboardtxt">Student Status : {studstatus==1?'ACTIVE':'INACTIVE'}</p>
                    
                    :<DashboardLoader/>}
                    </div>
                    {/* <div className="col-sm-3">
                      
                    </div> */}
                    <div className="col-sm-5">
                    {!loader?
                        <h6 className="card-title text-defult font-weight-bold">
                          <span style={{fontWeight:700, fontSize: 'large'}}>Notice Board</span><br/><br/>
                          <span style={{fontWeight:600, fontSize: 'medium'}} className="text text-danger">Notice : {noticename}. ( Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")} )</span><br/>
                          {/* <span style={{fontWeight:700, fontSize: 'medium'}}>Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")}</span> */}
                        </h6>
                        // <p>Notice : # Next new batch (Batch-13) is commenced from 8th January 2022 at 12.30 p.m. # We commence new batch in two different sessions - January (1st week) and July (1st Week). # This is 12 months course.
                        //  Date : 20-08-2021</p>
                    :<NoticeboardLoader/>}
                    </div>
                </div>

                
                <div className="row mb-4">
                     <div className="col-sm-12">
                         <h6 className="card-title text-defult font-weight-bold" style={txtwhite}><i classname="fa fa-seedling"></i> Upcoming Live Set Exam <button className="btn btn-default" onClick={()=>{
                           upcomingliveexam();
                         }}><i className="fas fa-sync"></i></button></h6>
                         <div className="bg_white border rounded">            
                            <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    {/* <th>Live Exam</th>
                                    <th>Start Time Left</th>
                                    <th>Exam Date</th>
                                    <th>Exam Time</th>
                                    <th className="text-center">Action</th> */}
                                    <th>Live Exam</th>
                                    <th>Exam Date</th>
                                    {/* <th>Start Time</th>
                                    <th>End Time</th> */}
                                    <th>Time</th>
                                    <th className="text-center">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                      
                                  {/* {upcominglivearr.map((e)=>
                                  <tr key={e.liveset_slno}>

                                  <td>{e.liveset_name}</td>
                                  <td>
                                  
                                    {startTimer(dateFormat(e.liveexamsetselection_end_schedule, "mmmm dd, yyyy HH:MM:00"),x+1)}
                                    {datetimeinterval}

                                  </td>
                                  <td>{dateFormat(e.liveexamsetselection_start_schedule, "dd-mm-yyyy")}</td>
                                  <td>{dateFormat(e.liveexamsetselection_start_schedule, "HH:MM")} - {dateFormat(e.liveexamsetselection_end_schedule, "HH:MM TT")}</td>
                                  <td className="text-center"><button className="btn btn-primary" disabled={isbtn} onClick={()=>{

                                    }}>Exam Soon</button></td>
                                  </tr>
                                  )} */}
                                {orderstatus==1?
                                  upcominglivearr.map((e)=>
                                  <tr key={e.liveset_slno}>

                                  <td style={txtwhite}>{e.liveset_name}</td>
                                  <td style={txtwhite}>{dateFormat(e.liveexamsetselection_start_schedule, "dd-mm-yyyy")}</td>
                                  {/* <td style={txtwhite}>{dateFormat(e.liveexamsetselection_start_schedule, "HH:MM TT")}</td>
                                  <td style={txtwhite}>{dateFormat(e.liveexamsetselection_end_schedule, "HH:MM TT")}</td> */}
                                  <td style={txtwhite}>{dateFormat(e.liveexamsetselection_start_schedule, "HH:MM TT")}-{dateFormat(e.liveexamsetselection_end_schedule, "HH:MM TT")}</td>
                                  <td className="text-center">
                                  {(new Date(dateFormat(e.liveexamsetselection_start_schedule, "mmmm dd, yyyy HH:MM:00")).getTime())-(new Date().getTime())<=0?
                                    e.existscorecount==0?
                                    <button className="btn btn-success" onClick={()=>{
                                      localStorage.setItem("livesetid",e.liveset_slno);
                                      window.location.href="/startliveexam";
                                    }}>Go Exam</button>
                                    :
                                    <button className="btn btn-danger" disabled='true' onClick={()=>{
                                      
                                    }}>Exam Done</button>
                                    
                                  :
                                    <button className="btn btn-info" disabled={isbtn} onClick={()=>{
                                      
                                    }}>Exam Soon</button>
                                  
                                  }
                                  
                                  </td>
                                  </tr>
                                  )
                                :
                                ''
                                }

                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>


                <div className="row mb-4">
                     <div className="col-sm-12">
                         <h6 className="card-title text-defult font-weight-bold" style={txtwhite}><i classname="fa fa-seedling"></i> Upcoming Practice Set Exam <button className="btn btn-default" onClick={()=>{
                           upcomingpracticeexam();
                         }}><i className="fas fa-sync"></i></button></h6>
                         <div className="bg_white border rounded table-responsive">            
                            <table className="table table-striped table-bordered">
                                <thead className="thead-dark">
                                  <tr>
                                    {/* <th>Live Exam</th>
                                    <th>Start Time Left</th>
                                    <th>Exam Date</th>
                                    <th>Exam Time</th>
                                    <th className="text-center">Action</th> */}
                                    <th>Practice Exam</th>
                                    <th>Exam Date</th>
                                    {/* <th>Start Time</th>
                                    <th>End Time</th> */}
                                    <th>Time</th>
                                    <th className="text-center">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                      
                                  {/* {upcominglivearr.map((e)=>
                                  <tr key={e.liveset_slno}>

                                  <td>{e.liveset_name}</td>
                                  <td>
                                  
                                    {startTimer(dateFormat(e.liveexamsetselection_end_schedule, "mmmm dd, yyyy HH:MM:00"),x+1)}
                                    {datetimeinterval}

                                  </td>
                                  <td>{dateFormat(e.liveexamsetselection_start_schedule, "dd-mm-yyyy")}</td>
                                  <td>{dateFormat(e.liveexamsetselection_start_schedule, "HH:MM")} - {dateFormat(e.liveexamsetselection_end_schedule, "HH:MM TT")}</td>
                                  <td className="text-center"><button className="btn btn-primary" disabled={isbtn} onClick={()=>{

                                    }}>Exam Soon</button></td>
                                  </tr>
                                  )} */}
                                {orderstatus==1?
                                  upcomingpracticearr.map((e)=>
                                  <tr key={e.practiceset_slno}>

                                  <td style={txtwhite2}>{e.practiceset_name}</td>
                                  <td style={txtwhite}>{dateFormat(e.practiceexamsetselection_start_schedule, "dd-mm-yyyy")}</td>
                                  {/* <td style={txtwhite}>{dateFormat(e.practiceexamsetselection_start_schedule, "HH:MM TT")}</td>
                                  <td style={txtwhite}>{dateFormat(e.practiceexamsetselection_end_schedule, "HH:MM TT")}</td> */}
                                  <td style={txtwhite}>{dateFormat(e.practiceexamsetselection_start_schedule, "HH:MM TT")}-{dateFormat(e.practiceexamsetselection_end_schedule, "HH:MM TT")}</td>
                                  <td className="text-center">

                                  {/* {e.existscorecount==0? */}
                                    {(new Date(dateFormat(e.practiceexamsetselection_start_schedule, "mmmm dd, yyyy HH:MM:00")).getTime())-(new Date().getTime())<=0?
                                    <button className="btn btn-success" onClick={()=>{
                                      localStorage.setItem("practicesetid",e.practiceset_slno);
                                      window.location.href="/startpracticeexam";
                                    }}>Go Exam</button>
                                    :
                                    <button className="btn btn-info" disabled={isbtn} onClick={()=>{
                                      
                                    }}>Exam Soon</button>}
                                  {/* : <button className="btn btn-danger" disabled={isbtn} onClick={()=>{
                                      
                                  }}>Exam Finish</button>} */}
                                  </td>
                                  </tr>
                                  )
                                  :
                                  ''
                                  }
                                

                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>

                {orderstatus==2?

                <div classname="row">
                <div classname="col-md-12 text-center">
                    <div classname="main-card mb-3 card" style={{background: 'rgba(246, 255, 122, 0.1)', borderRadius: '2%', border: '1px solid #ed3b00'}}>

                    <div className="card-body text-center">
                            <h5 classname="card-title text-center" style={{color: '##FF0000', fontWeight: 700, fontSize: 'large'}}>Please Pay One Month Fee For Account Activation OR Contact With <a href="tel:8013181226" style={{fontWeight: '700'}}>8013181226</a></h5>
                    </div>
                    </div>
                </div>
                </div>

                :''
                }

                {/* :

                ''}
                 */}
           

            </div>  
        </div>
       
        </>
    );
}

export default Dashboardcontent;