import react from "react";
import Header from "./../include/Header";
import Sidebar from "./../include/Sidebar";
import Startpracticeexamcontent from "./../content/Startpracticeexamcontent";

function Startpracticeexam() {
    return(
        <>
        <Header />
        <div className="wrapper"> 
            <Sidebar />
            <Startpracticeexamcontent />        
        </div>
        </>
    );
}

export default Startpracticeexam;