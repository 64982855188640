import react, { useEffect, useState } from "react";
import axios from 'axios';
import validator from 'validator';
import {Link} from 'react-router-dom';

import {useNavigate} from "react-router-dom";
// import ForgotPassword from "../pages/ForgotPassword";

function ForgotPasswordcontent() {

  let navigate = useNavigate();

  // const baseurl="https://www.safalata.in/safalatalara";

  if (localStorage.getItem("tokenID")!=null) {
    window.location.href="/dashboard";
  }

  // INPUT FIELD
  // let [loginid,setLoginid]=useState("");
  let [phone,setPhone]=useState("");
  let [dob,setDob]=useState("");
  // INPUT FIELD

  let [isbtn,setIsbtn]=useState(true);

  // VALIDATION
  // let errloginid="Valid Email ID Required";
  let errphone="Registered Phone Number Required";
  let errdob="Date of Birth Required";

  // let [iserrloginid,setIsrrloginid]=useState(false);
  let [iserrphone,setIsrrphone]=useState(false);
  let [iserrdob,setIsrrdob]=useState(false);
  // VALIDATION

  // MSG
  let [alert,setAlert]=useState(false);
  let [msg,setMsg]=useState("");
  let [msgalert,setMsgalert]=useState("");
  // MSG

  async function studLogin() {
    var flag=1;
    
    if (phone.length<10) {
      flag=0;
      setIsrrphone(true);
    }
    if (dob.length==0) {
      flag=0;
      setIsrrdob(true);
    }
    if (flag==1) {
        
        console.log(dob);

        var fd=new FormData();
        fd.append("phone",phone);
        fd.append("dob",dob);

        const response = await axios.post('https://safalata.in/safalatalara/api/studforgotpassword', fd);
        const data = response.data;

        setAlert(true);
        setMsg(data.msg);
        setMsgalert(data.alert);

        if (data.msgcode==1) {

            setIsbtn(true);
            setTimeout(()=>{
                localStorage.setItem('phone',phone);
                let path = `/update-password`; 
                navigate(path);
            }, 2000);

        }
      
    }
    
  }


  return(
    <>
    <div className="loginBoxWrap">
        <div className="loginBox">
          <div className="logoRow"><img src="images/logo2.png" alt="" /></div>
        	{/* <div className="bg_white2" style={{background: '#030088'}}> */}
          <div className="bg_white2" style={{background: '#fff'}}>
          <h2 style={{fontSize: 'medium'}}>Shraddha Group of Education</h2>
          <div className="loginBoxContener p-4">
            <p className="text-center" style={{color: '#000',fontSize: 'large'}}>Forgot Password</p>
              {alert?<div className={msgalert} style={{textAlign:'center'}}>{msg}</div>:''}
              <div className="form-group row">
                {/* <label for="inputEmail3" className="col-sm-12 col-form-label">Login ID</label>
                <div className="col-sm-12">
                  <input type="email" className="form-control" placeholder="Registered Email" onChange={(ev)=>{
                    setLoginid(ev.target.value);
                    if (validator.isEmail(loginid)) {
                      setIsrrloginid(false);
                    }
                    setAlert(false);
                  }}/>
                  {iserrloginid?<div className="text text-danger">{errloginid}</div>:''}
                </div> */}
                {/* <label for="inputPhone3" className="col-sm-12 col-form-label2">Registered Phone Number</label> */}
                {/* <label for="inputPhone3" className="col-sm-12" style={{color: '#fff'}}>Registered Phone Number</label> */}
                <div className="col-sm-12">
                  <label for="inputPhone3" className="col-sm-12" style={{color: '#000'}}>Registered Phone Number</label>
                  <input type="tel" maxLength={10} className="form-control" placeholder="Registered Phone Number" onChange={(ev)=>{
                    setPhone(ev.target.value);
                    if (ev.target.value.length==10) {
                      setIsrrphone(false);
                    }
                    setAlert(false);
                  }}/>
                  {iserrphone?<div className="text text-danger">{errphone}</div>:''}
                </div>
              </div>
              <div className="form-group row">
                {/* <label for="inputPassword3" className="col-sm-12 col-form-label" style={{color: '#fff'}}>Password</label> */}
                <div className="col-sm-12">
                  <label for="inputPhone3" className="col-sm-12" style={{color: '#000'}}>Date of Birth (e.g. Month(12)/Day(31)/Year(2005))</label>
                  <input type="date" className="form-control" placeholder="dd-mm-yyyy" onChange={(ev)=>{
                    setDob(ev.target.value);
                    if (ev.target.value.length>=1) {
                      setIsrrdob(false);
                    }
                    setAlert(false);
                  }} data-date="" data-date-format="dd-mm-yyyy" />
                  {iserrdob?<div className="text text-danger">{errdob}</div>:''}
                </div>
              </div>

              {/* <div className="form-group row">
                <div className="col-sm-12">
                  <div className="form-check small">
                    <input className="form-check-input" type="checkbox" id="gridCheck1" onChange={(ev)=>{
                      if (ev.target.checked) {
                        setIsbtn(false);
                      } else {
                        setIsbtn(true)
                      }
                    }} />
                    <label className="form-check-label " for="gridCheck1"> Remember Me </label>
                  </div>
                </div>
              </div> */}

              <div className="form-group row text-center">
                <div className="col-sm-12">
                  <div className="form-check p-0">
                    <button className="btn btn-primary" onClick={studLogin}>CLICK HERE</button>
                  </div>
                </div>
              </div>
              <div className="form-group row text-center">
                <div className="col-sm-12">
                  <div className="form-check p-0">
                    Login Account To <Link to="/">CLICK HERE</Link> 
                  </div>
                </div>
              </div>

          </div>
          </div>
         <div className="small mt-2 copyrighttext">&copy; Copyright 2022. All Rights Reserved.<br/>Powered By <a href="http://www.cheapwebguru.com" target="_blank">CHEAPWEBGURU.COM</a>.<br></br></div>
      </div>   
    </div>
    
    </>

  );
}

export default ForgotPasswordcontent;







