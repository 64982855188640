import React from "react"
import ContentLoader from "react-content-loader"

const AnswerLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={600}
    height={280}
    viewBox="0 0 600 280"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="29" cy="28" r="23" /> 
    <rect x="78" y="13" rx="5" ry="5" width="458" height="32" /> 
    <rect x="78" y="85" rx="5" ry="5" width="458" height="32" /> 
    <rect x="81" y="163" rx="5" ry="5" width="458" height="32" /> 
    <rect x="83" y="242" rx="5" ry="5" width="458" height="32" /> 
    <circle cx="29" cy="101" r="23" /> 
    <circle cx="31" cy="181" r="23" /> 
    <circle cx="33" cy="256" r="23" />
  </ContentLoader>
)

export default AnswerLoader